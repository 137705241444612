import React from 'react'
import { useSelector } from 'react-redux';
import { Addonbanner, TextHeading, Paragraph } from '../../Components'
import '../Terms/Terms.scss'

const mapStateToProps = ({ auth  }) => ({
  auth,
});

export default function Privacypolicy() {
  const { auth } = useSelector(mapStateToProps);

  return (
    <div>
        {/* <Addonbanner heading="Useful Information" smallheading="Last updated: Febaury 22, 2022"/> */}
        
        <div className='addon-container'>
            <div className='addon-content'>
                <Paragraph id="Aboutus" heading="About Us" lineHeight="2rem" text={auth.tenantDetails?.template_details?.about_us}/>
                <br />
                
                <Paragraph id="TermsConditions" heading="Terms & Conditions" lineHeight="2rem" text={auth.tenantDetails?.template_details?.terms_and_conditions} />
                <br />

                <Paragraph id="PhurtiDeliveryServices" heading={`${auth.tenantDetails?.title} Delivery Services`}  lineHeight="2rem" text={auth.tenantDetails?.template_details?.delivery_services}/>

                <br />
                
                <Paragraph id="PaymentMethods" heading="Payment Methods" lineHeight="2rem" text={auth.tenantDetails?.template_details?.payment_methods} />
                <br />

                <Paragraph id="ReturnPolicy" heading="Return Policy" lineHeight="2rem" text={auth.tenantDetails?.template_details?.return_policy} />
                <br />

                <Paragraph id="PrivacyPolicy" heading="Privacy Policy" lineHeight="2rem" text={auth.tenantDetails?.template_details?.privacy_policy} />
                <br />

                <br />

                {/* Contact Us Start  */}
                {/* <TextHeading fontWeight="bold" heading="Contact Us" id="ContactUs"/> */}
                {/* <br /> */}

                <Paragraph id="ContactUs" heading="Contact Us" lineHeight="2rem"/>
                <TextHeading fontWeight="bold" heading="Address"  fontSize=".8rem"/>
                <Paragraph heading=" " lineHeight="2rem" text={`${auth.tenantDetails?.address}`}/>
                <br />
                
                {
                  auth?.tenantDetails?.business_details &&  Object.entries(auth?.tenantDetails?.business_details).map(([key, values]) => {

                    let heading = '';
                    if(key === 'gst'){
                      heading = 'GST'
                    }else if(key == 'cin'){
                      heading = 'CIN'
                    }else if(key == 'phone_number'){
                      heading = 'Phone Number'
                    }else if(key == 'email'){
                      heading = 'Email'
                    }else{
                      heading = key;
                    }

                    return (
                      <>
                        <TextHeading fontWeight="bold" heading={heading}  fontSize=".8rem"/>
                        <Paragraph heading=" " fontWeight="bold" text={`${values || "+918861144646"}`} />
                        <br/>
                      </>
                    )
                  })
                }
            </div>
        </div>
        {/* <div className="addonCenter" id="ContactUs">
          <Paragraph text="For any further queries, drop us an email at" lineHeight="2rem"/>
          <a href="mailto:tech@phurti.in">
            <TextHeading fontWeight="bold" heading="team@phurti.in" id="PrivacyPolicy" fontSize="1.5rem"/> 
          </a>

        </div> */}
    </div>
  )
}
