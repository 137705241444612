import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  Categories,
  Foryou,
  Navbar,
  Offers,
  Help,
  Bottom,
  Install,
  WhyShop,
  Footer,
  Login,
  Overlay,
  Modal,
  Navigation,
  SearchResults,
} from "../../Components";

import { actionsCreator } from "../../Redux/actions/actionsCreator";
import giftIcon from "../../Assets/Images/gifs/gift.gif";
import medkit from "../../Assets/Images/medkit.png";
import girlwithmeds from "../../Assets/Images/girlwithmeds.png";
import bluebg from "../../Assets/Images/bluebg.png";

import tablets from "../../Assets/Images/tablets.png";
import playVideo from "../../Assets/Images/play-video.png";
import doctorVideo from "../../Assets/Images/doctor-video.png";

import { FaChevronCircleLeft, FaChevronCircleRight, FaPlayCircle } from "react-icons/fa";
import "./Home.scss";
import { welcomeModalHandler } from "../../Utils";
import Bannerv2 from "../../Components/Homepage/Bannerv2";
import Slideshow from "../../Components/Homepage/Bannerv2/Slideshow";
import Dashboard from "../dashboard";
import ProductsSlider from "../../Components/ProductCategory/Products";
import Container1 from "../../Components/Container/Container1";
import { CiSearch } from "react-icons/ci";
import Caraousel from "../../Components/Caraousel/Caraousel";
import { useHistory } from "react-router-dom";
import useDebounce from "../../Hooks/useDebounce";
import Home1 from "../../Assets/Images/Home1.png";
import Home2 from "../../Assets/Images/Home2.png";
import Home3 from "../../Assets/Images/Home3.png";
import Home4 from "../../Assets/Images/Home4.png";

const mapStateToProps = ({ auth, categories = {} }) => ({
  auth,
  categories,
});

export const BannerComponent = ({data}) => {
  return (
    <div
      className="flex repsonsive-flex-row banner-component-homedeisgn"
    >
      <div
        className="flex-1 flex justify-end items-center"
        style={{
          paddingRight: "2rem",
        }}
      >
        <Container1
          index={1}
          header1={data?.images[0]?.text || 'We Love Minimialism'}
          header2={data?.images[0]?.description || 'Construction'}
        >
          <button 
            onClick={() => {
              document.getElementById('category-component').scrollIntoView({behavior : 'smooth'})
            }}
            className="btn-active-homedesign btn-md"
          >
            View Projects
          </button>
        </Container1>
      </div>
      <img
        src={data?.images[0]?.uri || Home1}
        style={{
          flex: "1",
          objectFit: "contain",
        }}
        className=""
        loading="lazy"
      />
    </div>
  );
};

export const CategoryComponent = ({categoryList}) => {
  const history = useHistory();

  return (
    <div
      id="category-component"
      className="flex responsive-flex-row w-full category-wrapper-homedesign"
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        className="category-component-homedesign"
      >
        <Container1
          index={"2"}
          header1={"New Arrivals"}
          header2={
            "We've designed and curated pieces that are a cut above your average home goods, because when you level up your everyday objects, you elevate your daily rituals."
          }
        >
          <button className="btn-active-homedesign btn-sm">View All</button>
        </Container1>
      </div>
      <div
        className="flex-1 w-full"
        style={{
          padding: "1rem",
          marginTop : '1rem'
        }}
      >
        <Caraousel>
          {
          categoryList.map((item, index) => {
            return (
              <button
                className=""
                style={{
                  display: "inline-flex",
                  flexDirection: "column",
                  marginRight: "1%",
                }}
                onClick={() => {
                  history.push(`/categories/${item.name}`);
                }}
              >
                <img
                  src={item.image}
                  className=""
                  style={{
                    objectFit: "contain",
                    height: "18rem",
                    aspectRatio : '1'
                  }}
                />
                <div
                  className="flex flex-row justify-between"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <span
                    className="text-sm bold-lg flex-1"
                    style={{
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name || `Category ${index+1}`}
                  </span>
                  {/* <span
                    className="text-sm bold-lg flex-1 flex flex-row"
                    style={{
                      overflowX: "hidden",
                      textOverflow: "ellipsis",
                      justifyContent: "flex-end",
                    }}
                  >
                    INR {"24,400"}
                  </span> */}
                </div>
              </button>
            );
          })}
        </Caraousel>
      </div>
    </div>
  );
};

export const HomePageCategory = ({ categoryList = [] }) => {

  const history = useHistory();

  const caraRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, clientWidth } = document.getElementById('caraousel-image');

    // Check if there is some scrolling towards the right
    setShowLeftButton(scrollLeft > 0);

    // You can also check if scrolling towards the right end to hide right button if needed
    // const showRightButton = scrollLeft < scrollWidth - clientWidth;
  };

  const handleLeftClick = () => {
    console.log("clicked left")
    document.getElementById('caraousel-image').scrollLeft -= 50; // Adjust the scrolling amount as needed
  };

  const handleRightClick = () => {
    console.log("clicked right", document.getElementById('caraousel-image').scrollLeft)
    document.getElementById('caraousel-image').scrollLeft = document.getElementById('caraousel-image').scrollLeft +  50; // Adjust the scrolling amount as needed
  };


  return (
    <div
      className="w-screen homepage-cat-homedesign"
      style={{
        backgroundColor: "black",
        aspectRatio : '3/2',
        overflowX : 'hidden'
      }}
    >
      <div className="flex flex-col gap-10 h-full w-full">
        <p className="flex flex-row justify-end">
          <span
            style={{
              color: "#797979",
            }}
          >
            We design your <span style={{ color: "white" }}>home</span>{" "}
          </span>
        </p>
        <div
          className="flex-1 scrollbar-hidden relative w-full"
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <button
            className="absolute"
            style={{
              left: "1.3rem",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: "5",
              visibility: showLeftButton ? "visible" : "hidden",
            }}
            onClick={handleLeftClick}
          >
            <FaChevronCircleLeft size={"2rem"} color={"#30363C"} />
          </button>
          <button
            className="absolute"
            style={{
              right: "1.3rem",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: "5",
            }}
            onClick={handleRightClick}
          >
            <FaChevronCircleRight size={"2rem"} color={"#30363C"} />
          </button>
          <div
            ref={caraRef}
            id="caraousel-image"
            className={'scrollbar-hidden h-full w-full relative'}
            style={{
              overflowX: "scroll",
              overflowY: "hidden",
              whiteSpace: "nowrap",
              scrollSnapType: "x mandatory",
              scrollBehavior: "smooth",
            }}
            onScroll={handleScroll}
          >
            {
              categoryList.map((item) => {
                if(item.home_page){
                  return (
                    <img
                      src={item.image}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit : 'contain',
                        aspectRatio: "1",
                        scrollSnapAlign: "center",
                      }}
                      onClick={() => {
                        history.push(`/categories/${item.name}`);
                      }}
                    />
                  )
                }
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const WhyUsComponent = ({ data }) => {
  return (
    <div className="p-2">
      <Container1
        index={"3"}
        header1={"Designed For You"}
        header2={
          "We've designed and curated pieces that are a cut above your average home goods, because when you level up your everyday objects, you elevate your daily rituals. Let the magic happen."
        }
        flexDirection={"responsive-flex-row items-center gap-10"}
        className="gap-20 flex flex-col"
      >
        <div className="flex responsive-flex-row gap-20">
          <img

            src={Home2}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
        <div className="flex responsive-flex-row gap-20">
          <img
            src={Home2}
            className="flex-1"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
          <img
            src={Home3}
            className="flex-1 whyus-comp-firstimg"
            style={{
              objectFit: "cover",
              height: "90vh",
            }}
          />
        </div>
      </Container1>
    </div>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth,
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const { isLoggedIn, tenantDetails = {} } = auth;

  const [home, setHome] = useState({});

  const [offers, setOffers] = useState(true);
  const [subcategory, setSubcategory] = useState([]);

  const subcategoryAdd = () => {
    let temp_sub_category = [];
    categoryList.map((item) => {
      item.sub_categories &&
        item.sub_categories.map((subitem) => {
          if (subitem.home_page) {
            temp_sub_category.push(subitem);
          }
        });
    });
    setSubcategory(temp_sub_category);
  };

  useEffect(() => {
    subcategoryAdd();
  }, [categoryList]);

  const fetchCartDetails = () => {
    dispatch(actionsCreator.FETCH_CART_DETAILS());
  };

  const fetchFavouriteProducts = () => {
    dispatch(actionsCreator.FETCH_FAVOURITE_PRODUCTS());
  };

  // const [modal, setModal] = useState(true);
  useEffect(() => {

    if(tenantDetails.template_configs){

      const {
        home 
      } = tenantDetails?.template_configs[0]?.config;

      setHome(home)
    }

    if (isLoggedIn) {
      fetchCartDetails();
      fetchFavouriteProducts();
    }

    setActiveCategory(categoryList[0]);

  }, [auth]);

  const [activeCategory, setActiveCategory] = useState({});

  const [query, setQuery] = useState("");
  const debouncedSearch = useDebounce(query, 500);

  return (
    <div className="home">
      <BannerComponent 
        data = {home?.banner}
      />

      {
        home?.categories && 
        categoryList.length > 0
        && 
        <CategoryComponent 
          categoryList={categoryList} 
        />
      }

      { home?.offers && <Offers /> }

      {
        home?.featured_categories 
        && 
        categoryList.length > 0
        &&
        <HomePageCategory
          categoryList={categoryList}
        />
      }

      { home?.why_us && <WhyUsComponent/> }
    </div>
  );
};

export default Home;
