// import React from "react";
// import "./InputField.scss";

// function InputField({
//   placeholder,
//   type,
//   onChange,
//   value,
//   disabled,
//   error = "",
//   label,
//   name,
//   id,
//   autofocus,
//   max,
//   cn,
// }) {
//   return (
//     <div className="w-full" style={{ cursor : 'pointer' }}>
//       {
//       label
//        && 
//       <div 
//         className="text-medium text-bold-md"
//         style={{
//           marginBottom : '5px'
//         }}
//       >{label}</div>
//       }
//       <input
//         type={type}
//         placeholder={placeholder}
//         onChange={onChange}
//         value={value}
//         disabled={disabled}
//         name={name}
//         id={id}
//         autoFocus={autofocus}
//         max={max}
//         className="w-full p-4 text-medium"
//         style={{
//           outline : 'none',
//           border : '1px solid #f2f2f2',
//         }}
//       />
//       {error && <div className="err-div">{error}</div>}
//     </div>
//   );
// }

// export default InputField;

import React from "react";
import PropTypes from "prop-types";
import "./InputField.scss";

export default function LabelledInput({
  label,
  placeholder,
  value,
  type,
  name,
  onChange,
  error,
  textarea,
  className,
  required,
}) {
  console.log("error", error)

  return (
    <div className={`LabelledInput ${className}`}>
      {label && 
        <div 
          className="LabelledInput__Label"
          style={{
            fontWeight : required ? '500' : '300',
          }}
        >{label}</div>}
      {!textarea ? (
        <input
          className="LabelledInput__Input p-3"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required={ type === "search" && required }
        />
      ) : (
        <textarea
          className="LabelledInput__Input TextArea"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required
          rows={"3"}
        />
      )}
      {error && (
        <div className="LabelledInput__Error">{error.msg}</div>
      )}
    </div>
  );
}

LabelledInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: () => {},
  error: PropTypes.object,
  textarea: PropTypes.bool,
};

LabelledInput.defaultProps = {
  label: "",
  placeholder: "",
  type: "text",
  value: "",
  bool: {},
  type: "text",
  textarea: false,
};

