import { BrowserRouter, Route, Switch, Router } from 'react-router-dom';
// import Checkout from './Pages/Checkout/Checkout';
// import Comingsoon from './Pages/Comingsoon/Comingsoon'
// import Feedback from './Pages/Feedback/Feedback';
// import Feedbackthank from './Pages/Feedback/Feedbackthank';
// import Allorder from './Pages/Allorder/Allorder'
// import AllRecentOrder from './Pages/AllRecentOrder/AllRecentOrder';
import './App.scss'

import {
  Home,
  Category, 
  Checkout,
  OrderSuccess,
  Timer,
  EverythingDelivery,
  ActiveOrders,
  Favourite,
  AddStock,
  Invoice,
  PunchEorder,
  WastedProduct,
  Terms,
  Shippingpolicy,
  Privacypolicy,
  Aboutus,
  Contactus
} from './v1/Pages';

import { Navigation, Footer, Bottom, ProductCard, Auth } from './v1/Components';
import configureStore from './v1/Redux/store/configureStore';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './v1/Components/ScrollToTop/ScrollToTop';
import { AuthWrapper } from './v1/Wrapper/'
import Carditems from './v1/Components/cardItems/CardItems';
import TenantDashboard from './v1/Pages/TenantDashboard/TenantDashboard';

// import './App.scss'

import { AllProduct } from './v1/Components/ProductCategory/Products.js';
import { useEffect } from 'react';

export const { store } = configureStore();

function App() {

  const contextClass = {
    info: "toast-info",
    error: "toast-error",
    success: "toast-success",
  };

  return (
    <div 
      className="AppRoot font-poppins relative overflow-y-auto overflow-x-hidden"
      style={{ width : '100%'}}
    >
      <Provider store={store}>
        <Switch>
          <Route path='/dashboard' render={() => (<AuthWrapper><TenantDashboard /></AuthWrapper>)} />
          <Route path="/">
            <Navigation /> 
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path={'/allproducts'} component={AllProduct} />
              {/* <Route exact path="/comingsoon" component={Comingsoon} /> */}
              <Route path="/categories/:category" component={() => (<AuthWrapper> <Category /> </AuthWrapper> )} />
              <Route path="/categories" component={() => (<AuthWrapper><Category /></AuthWrapper>)} />
              <Route path="/everythingdelivery" component={() => (<AuthWrapper><EverythingDelivery /></AuthWrapper>)} />
              <Route path="/addstock/:id/" component={AddStock} />
              <Route exact path="/order-placed" component={OrderSuccess} />
              <Route path="/invoice/:orderid" component={Invoice} />
              <Route path="/add/everythingorder/:id" component={PunchEorder} />
              <Route path="/myorders" component={() => (<AuthWrapper> <ActiveOrders /> </AuthWrapper>)} />
              <Route path="/checkout" component={() => ( <AuthWrapper> <Checkout /> </AuthWrapper> )} />
              <Route path="/wastedproduct" component={WastedProduct} />
              <Route path="/wishlist" component={() => (<AuthWrapper><Favourite /></AuthWrapper>)} />
              <Route path="/privacy-policy" component={Privacypolicy} />
              <Route path='/orderItems' render={() => <Carditems />} />
              {/* <Route path="/aboutus" component={Aboutus} />
                <Route path="/contactus" component={Contactus} /> */}
              <Route path="*" component={Home} />
            </Switch>
            <Footer/>
          </Route>
        </Switch>
      </Provider>
      <ToastContainer
        bodyClassName={'toast-body'}
        autoClose={1500}
        closeOnClick
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnHover
        position="top-right"
        theme="dark"
        toastClassName={({ type }) => contextClass[type || 'default'] + ' toast-container'}
        limit={1}
      />
    </div>
  );
}

export default App;
